"use client";
import "src/index.css"

import {
  Box,
  HStack,
  useColorModeValue,
  Heading,
  Center,
  Flex,
  Button,
  Link,
} from "@chakra-ui/react";
import { NavLink } from "src/components/mobile-nav";
import LayoutDefault from "src/layouts/LayoutDefault";
import React from "react";
import { getAccessToken } from "src/authUtils";


const LINKS: Array<LinkItemProps> = [
  // { name: "Use Cases", href: "#use-cases" },
  { name: "Demo", href: "/#demo" },
  { name: "Templates", href: "/#templates" },
  { name: "Pricing", href: "/#pricing" },
  // { name: "Gallery", href: "/gallery" },
  { name: "FAQ", href: "/#faq" },
];


const LayoutLanding = ({ children }) => {
  const navbarHeight = "66px";
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const bg = useColorModeValue("white", "gray.900");
  const [loginToken, setLoginToken] = React.useState('unknown');

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setLoginToken(getAccessToken())
    }
  }, []);


  return (
    <LayoutDefault>
      <Box as={"header"} position={"fixed"} top={0} w="100%" zIndex={1000}>
        <Flex
          px={{ base: 2, md: 16 }}
          justify="space-between"
          align="center"
          bg={bg}
          h={navbarHeight}
          borderBottomColor={borderColor}
          borderBottomWidth='1px'
        >
          <Heading size='md' display='inline-block' as={Link} href="/">
            Plurally
          </Heading>
          <HStack
            as={"nav"}
            spacing={4}
            display={{ base: "none", md: "inherit" }}
          >
            {LINKS.map((link) => (
              <NavLink
                href={link.href}
                key={`top-${link.name}`}
                isExternal={link.isExternal}
              >
                {link.name}
              </NavLink>
            ))}
          </HStack>
          {loginToken === 'unknown' ? (
            <Button variant="link" me="2" as={Link} href="/auth" visibility='hidden'>Sign in</Button>
          ) : loginToken ? (
            <Button colorScheme="brand" me="2" as={Link} href="/studio" size='sm'>
              Dashboard
            </Button>
          ) : (
            <Button variant="link" me="2" as={Link} href="/auth">Sign in</Button>
          )}
        </Flex>
      </Box>
      <Box
        as={"main"}
        mt={navbarHeight}
        minH={`calc(100vh - ${navbarHeight})`}
        w="100%"
        display="flex"
        flexDirection="column"
      >
        {children}
      </Box>
      <Box bg={bg}>
        <Box py={12}>
          <Center>
            <HStack spacing={4}>
              <Link href="/blog">Blog</Link>
              <Link href="/privacy">Privacy Policy</Link>
            </HStack>
          </Center>
        </Box>
      </Box>
    </LayoutDefault>
  );
};

export default LayoutLanding;
